<template>
    <div>
        <button
            :id="`accordion-header-${_uid}`"
            class="flex items-center justify-between bg-left-bottom bg-no-repeat w-full focus:outline-none"
            :class="headerClasses"
            :aria-controls="`accordion-panel-${_uid}`"
            :aria-expanded="`${isActive}`"
            @click="select"
        >
            <slot name="heading" :active="isActive"></slot>
        </button>

        <section
            :id="`accordion-panel-${_uid}`"
            ref="panel"
            :aria-labelledby="`accordion-header-${_uid}`"
            :style="{ height: height }"
            class="overflow-hidden transition-all duration-300 ease-in-out"
            :hidden="isHidden"
        >
            <div ref="content">
                <slot name="content"></slot>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'AccordionItem',

    props: {
        headerClasses: {
            type: String,
            default: 'p-4 bg-gray-50 border-b',
        },

        active: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isActive: this.active,
            isHidden: !this.active,
            height: '0px',
            _uid: crypto.randomUUID(),
        };
    },

    mounted() {
        this.setHeight();

        let { panel } = this.$refs;

        panel.addEventListener('transitionend', () => {
            if (getComputedStyle(panel).height === '0px') {
                this.isHidden = true;
            }
        });
    },

    methods: {
        select() {
            this.isActive = !this.isActive;

            if (this.isActive) {
                this.isHidden = false;
            }

            this.$nextTick(() => {
                this.setHeight();
            });
        },

        setHeight() {
            let { content } = this.$refs;
            let contentHeight = getComputedStyle(content).height;

            this.height = this.isActive ? contentHeight : '0px';
        },
    },
};
</script>
